/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import styled from "styled-components";
import { Scribi } from "./utilities/api/store/store";
import { IconButton } from "./Scribi-Components/ScribiComponents";
import Icons from "./Icons/Icons";
import { Button } from "react-bootstrap";
import ScribiRestService from "./utilities/firestore/ScribiRestService";

const ContentContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  gap: 1rem;
  color: #555;
`;

const LineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-left: ${(props) =>
    props.indent ? `${props.indent + "rem"}` : "1rem"};
  width: 100%;
  box-sizing: border-box;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  h6 {
    margin: 0;
    font-weight: 600;
  }
`;

const Account = () => {
  const { user, paymentIds } = useContext(Scribi);
  const [manage, setManage] = React.useState(false);
  const [willUpgrade, setWillUpgrade] = React.useState(false);
  const [upgradeCharge, setUpgradeCharge] = React.useState(null);
  const [upgradeDetails, setUpgradeDetails] = React.useState(null);

  const handleEditEmail = () => {};

  const handleEditDisplayName = () => {};

  const handlePayoff = async () => {
    try {
      let { url } = await ScribiRestService.createStripeSession(
        paymentIds[user.payments_left],
        "payment",
        true
      );
      window.open(url, "_blank");
    } catch (error) {
      console.log("Error paying off subscription", error);
    }
  };

  const getUserLicenseStat = () => {
    if (user.purchased) {
      return (
        <LineItem>
          <h6>License:</h6>
          <label>Purchased</label>
        </LineItem>
      );
    }
    if (user.subscription) {
      return (
        <>
          <LineItem>
            <h6>License:</h6>
            <label>{user.payments_left} payments left</label>
          </LineItem>
          <LineItem>
            <h6>Payoff:</h6>
            <Button
              className="account-button"
              aria-label="Payoff Subscription"
              variant="primary"
              title="Payoff remaining license payments now"
              onClick={handlePayoff}
            >
              Pay remaining{" "}
              {user.payments_left === 11
                ? "$117.00"
                : `$${user.payments_left * 10.75}`}
            </Button>
          </LineItem>
          <LineItem>
            <h6>Final Total:</h6>
            <label>
              {`$${(
                (12 - user.payments_left) * 12 +
                (user.payments_left < 11 ? 10.75 * user.payments_left : 117)
              ).toFixed(2)}`}
            </label>
          </LineItem>
        </>
      );
    }
  };

  const getUserSubscriptionStat = () => {
    if (user.scribi_pro || user.scribi_plus) {
      return (
        <LineItem>
          <h6>Subscription:</h6>
          <label>
            {user.scribi_pro && "Subscribed to Scribi Pro"}
            {user.scribi_plus && "Subscribed to Scribi Plus"}
            {(user.scribi_pro || user.scribi_plus) && (
              <Button
                onClick={() => {
                  setManage(!manage);
                }}
              >
                Manage Subscription
              </Button>
            )}
          </label>
        </LineItem>
      );
    } else {
      return (
        <LineItem style={{ alignItems: "flex-start" }}>
          <h6>Subscription:</h6>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <Button
              onClick={async () => {
                try {
                  let { url } = await ScribiRestService.createStripeSession(
                    "price_1QGRjjGNKV5mxzQaoWuWSnHv",
                    "subscription",
                    true,
                    { product: "scribi_plus" }
                  );
                  window.open(url, "_blank");
                } catch (error) {
                  console.log("Error subscribing to Scribi Plus: ", error);
                }
              }}
            >
              Subscribe to Scribi Plus
            </Button>
            <Button
              onClick={async () => {
                try {
                  let { url } = await ScribiRestService.createStripeSession(
                    "price_1QGRksGNKV5mxzQaYVqcVfC3",
                    "subscription",
                    true,
                    { product: "scribi_pro" }
                  );
                  window.open(url, "_blank");
                } catch (error) {
                  console.log("Error subscribing to Scribi Pro: ", error);
                }
              }}
            >
              Subscribe to Scribi Pro
            </Button>
          </div>
        </LineItem>
      );
    }
  };

  const getSubscriptionLabel = () => {
    if (user?.scribi_pro) {
      return "Scribi Pro";
    }
    if (user?.scribi_plus) {
      return "Scribi Plus";
    }
    return "Subscription";
  };

  const handleUpgrade = async () => {
    let subscriptionDetails = await ScribiRestService.getSubscriptionDetails(
      user?.scribi_plus
    );
    let details = subscriptionDetails.subscription;
    let period_start = details.current_period_start;
    let period_end = details.current_period_end;
    let msPeriodEnd = period_end * 1000;
    let msNow = Date.now();
    let msUpgrade = msPeriodEnd - msNow;
    let daysUpgrade = Math.floor(msUpgrade / (1000 * 60 * 60 * 24));
    let daysInPeriod = Math.floor((period_end - period_start) / (60 * 60 * 24));
    let charge = ((25 / daysInPeriod) * daysUpgrade).toFixed(2);
    setUpgradeDetails({
      subscription: details.id,
      customer: details.customer,
    });
    setUpgradeCharge(charge);
    setWillUpgrade(true);
  };

  const displayUpgrade = () => {
    return (
      <LineItem indent="3" style={{ alignItems: "flex-start" }}>
        <h6>Upgrade Charge:</h6>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem", maxWidth: "50%" }}
        >
          <label>If you upgrade to Scribi Pro, you'll be charged ${upgradeCharge} today. After that, you'll be charged $25 on a recurring monthly basis until your subscription is cancelled. If you're ready to upgrade, click "Confirm Upgrade" below.</label>
            <Button onClick={handleConfirmUpgrade}>
                Confirm Upgrade
            </Button>
        </div>
      </LineItem>
    );
  };

    const handleConfirmUpgrade = async () => {
        try {
            let res = await ScribiRestService.upgradeSubscription(upgradeDetails)
            console.log("Upgrade response: ", res)
        } catch (error) {
            console.log("Error upgrading subscription: ", error)
        }
    };

  return (
    <ContentContainerColumn>
      <h4>Your Account</h4>
      <LineItem>
        <h6>Email:</h6>
        <label>
          {user?.email}
          <IconButton onClick={handleEditEmail}>
            <Icons.EditIcon />
          </IconButton>
        </label>
      </LineItem>
      <LineItem>
        <h6>Display Name:</h6>
        <label>
          {user?.displayName}
          <IconButton onClick={handleEditDisplayName}>
            <Icons.EditIcon />
          </IconButton>
        </label>
      </LineItem>
      {getUserLicenseStat()}
      {/* {getUserSubscriptionStat()}
      {manage && (
        <>
          <LineItem indent="2">
            <h6>Cancel Subscription:</h6>
            <label>
              <Button
                onClick={async () => {
                  if (user?.scribi_plus) {
                    await ScribiRestService.cancelSubscription(
                      user?.scribi_plus
                    );
                  } else if (user?.scribi_pro) {
                    await ScribiRestService.cancelSubscription(
                      user?.scribi_pro
                    );
                  }
                }}
              >
                Cancel {getSubscriptionLabel()}
              </Button>
            </label>
          </LineItem>
          {user?.scribi_plus && (
            <>
            <LineItem indent="2">
              <h6>Upgrade To Scribi Pro:</h6>
              <label>
                <Button onClick={handleUpgrade}>Upgrade to Scribi Pro</Button>
              </label>
            </LineItem>
            {willUpgrade && displayUpgrade()}
            </>
          )}
          {user?.scribi_pro && (
            <LineItem indent="2">
              <h6>Downgrade To Scribi Plus:</h6>
              <label>
                <Button
                  onClick={async () => {
                    await ScribiRestService.downgradeSubscription(
                      user?.scribi_pro
                    );
                  }}
                >
                  Downgrade to Scribi Plus
                </Button>
              </label>
            </LineItem>
          )}
        </>
      )} */}
    
    </ContentContainerColumn>
  );
};

export default Account;
